/* eslint-disable react/prop-types */
import loadable from '@loadable/component';
import classnames from 'classnames';
import React, { useEffect } from 'react';
import { useLoaderData, useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Helmet } from 'react-helmet';
import { useIntl } from 'react-intl';

import styles from './CategoryPage.module.scss';

import { clipTextEllipsis } from '../../utils/textEllipsis';

import loadVisibleComponent from '../../utils/loadVisibleComponent';
import CategoryBreadcrumb from './CategoryBreadcrumb';
import BrandDiscounts from '../BrandDiscounts/BrandDiscounts';
// import ShopDiscounts from '../ShopDiscounts/ShopDiscounts';
import RecentProducts from '../RecentProducts/RecentProducts';
import { getFullPagePath } from '../../utils/appRoutes';
import { ARD_CATEGORY_CONTENT } from '../../utils/appRoutes.definitions';
import { setRecommendationCategory } from '../../state/recommendation/recommendation';

import { SRPWrapper } from '../SRPWrapper/SRPWrapper';

const CategoryDescription = loadable(() => import('../CategoryDescription/CategoryDescription'));
const RecentlyViewedProducts = loadVisibleComponent(() => import('../RecentlyViewedProducts/RecentlyViewedProducts'));
const StickyHeader = loadable(() => import('../StickyHeader/StickyHeader'));
const Footer = loadVisibleComponent(() => import('../Footer/Footer'));
const NewsletterSection = loadVisibleComponent(() => import('../NewsletterSection/NewsletterSection'));
const ListCategories = loadable(() => import('../ListCategories/ListCategories'));

const CategoryPage = ({ categoryPath, config }) => {
  const intl = useIntl();
  const { recommendationCategory } = useSelector((state) => state.recommendation);
  const dispatch = useDispatch();
  const categoriesInUrl = categoryPath.split('/').filter(Boolean);
  //Get SEO data for categories from path
  const { categoryData } = useLoaderData();

  //Get requested categories names
  const categoryFilter = categoryData?.map(({ name }) => name) || [];
  const topCategoryName = categoryData?.[0]?.name || '';

  useEffect(() => {
    if (topCategoryName && topCategoryName !== recommendationCategory) {
      dispatch(setRecommendationCategory(topCategoryName));
    }
  }, [topCategoryName]);

  const canonicalUrl = getFullPagePath(ARD_CATEGORY_CONTENT, categoryPath);
  const lastSubcategory = categoryFilter?.slice(-1)[0];
  const lastSubCategoryData = categoryData[categoryData.length - 1];

  //todo: verify that product number should be replaced
  const metaTitle = lastSubCategoryData?.meta?.yoast_wpseo_title?.replace('%%numberProductsCategory%%', 0);
  const metaDescription =
    lastSubCategoryData?.effective_meta_description ||
    lastSubCategoryData?.meta?.yoast_wpseo_metadesc.replace('%%numberProductsCategory%%', 0);
  const categoryPageTitle = intl.formatMessage(
    {
      id: 'search.title',
    },
    { category: lastSubcategory, website: config.websiteName }
  );

  return (
    <React.Fragment>
      <Helmet>
        <meta property="og:url" content={canonicalUrl} />
        <link rel="canonical" href={canonicalUrl} />
      </Helmet>
      {!!categoryPageTitle && (
        <Helmet>
          <title>{clipTextEllipsis(categoryPageTitle, 60)}</title>
        </Helmet>
      )}
      {!!metaTitle && (
        <Helmet>
          <meta name="twitter:title" content={clipTextEllipsis(metaTitle, 70)} />
          <meta property="og:title" content={clipTextEllipsis(metaTitle, 50)} />
        </Helmet>
      )}
      {!!metaDescription && (
        <Helmet>
          <meta name="description" content={metaDescription} />
          <meta name="twitter:description" content={clipTextEllipsis(metaDescription, 200)} />
          <meta property="og:description" content={clipTextEllipsis(metaDescription, 160)} />
        </Helmet>
      )}
      <StickyHeader
        selectedCategoriesData={categoryData}
        rootCategorySlug={categoriesInUrl[0]}
        categoriesInUrl={categoriesInUrl}
      />
      <div className={classnames(styles.root, 'container')}>
        <div className={classnames('row')}>
          <h1
            className={classnames(styles.pageHeading, 'col-12-xs')}
            id="readyForCrawler"
            data-testid="CategoryContentPageTitle"
          >
            {lastSubcategory}
          </h1>
        </div>
        <div className={classnames('row', styles.breadcrumbsWrapper)}>
          <CategoryBreadcrumb categories={categoryData} />
        </div>
      </div>
      <ListCategories
        categoryPath={categoryPath}
        lastSubcategory={lastSubcategory}
        categories={lastSubCategoryData?.categories}
        categoryNamesFromPath={categoryData.map(({ name }) => name)}
      />
      <CategoryDescription categoryData={categoryData} />
      {!!lastSubCategoryData?.brand_discounts?.length && (
        <BrandDiscounts brandDiscounts={lastSubCategoryData?.brand_discounts} categoryPath={categoryPath} />
      )}
      {/* {!!lastSubCategoryData?.shop_discounts?.length && (
        <ShopDiscounts shopDiscounts={lastSubCategoryData?.shop_discounts} categoryPath={categoryPath} />
      )} */}
      <CategoryBreadcrumb categories={categoryData} isMobile />
      <RecentProducts categoryPath={categoryPath} />
      {/* categoryData contains categories seo data */}
      <RecentlyViewedProducts />
      <NewsletterSection />
      <Footer />
    </React.Fragment>
  );
};
CategoryPage.displayName = 'CategoryPage';

const CategoryWrapped = () => {
  const config = useSelector(({ config }) => config);
  const location = useLocation();
  const { pathname } = location;
  const categoryPath = pathname.substring(1);

  return (
    <SRPWrapper>
      <CategoryPage categoryPath={categoryPath} config={config} />
    </SRPWrapper>
  );
};

export default CategoryWrapped;
