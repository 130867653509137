import { useEffect, useCallback } from 'react';
import { useFrontload } from 'react-frontload';
import { getValidResponseBody, searchApi } from '../api';
import { useAuth0Wrapper } from './auth0';
import useClientEffect from './useClientEffect';

export const RECENT_10_PRODUCTS = 'RECENT_10_PRODUCTS';
export const DISCOUNTED_10_PRODUCTS = 'DISCOUNTED_10_PRODUCTS';

const productListArgs = {
  [RECENT_10_PRODUCTS]: [10, 'new', ''],
  [DISCOUNTED_10_PRODUCTS]: [10, 'new', 'korting'],
};

const getProducts = (count = 10, sortBy = 'new', query = '', categoryPath) => (searchApi, authToken) => {
  const categoriesInUrl = categoryPath.split('/').filter(Boolean);

  const searchPayload = {
    payload: {
      filters: { price: [null, null], category: categoriesInUrl },
      page: { current: 0, size: count },
      q: query,
      sort: { by: sortBy },
    },
  };

  const options = {
    headers: authToken ? { Authorization: `Bearer ${authToken}` } : {},
    method: 'POST',
    body: JSON.stringify(searchPayload),
  };
  return searchApi.getSearchData(options);
};

/**
 * Provide product lists.
 *
 * @param {boolean[]} ssrDeplist - List of boolean values for `listsToReturn` items.
 * Each value specifies if corresponding request should be invoked on SSR.
 * @returns {object[]} // or specify the actual return type
 */
export const useSearchProducts = (categoryPath, listsToReturn = [RECENT_10_PRODUCTS], ssrDeplist) => {
  const { tokenClaims } = useAuth0Wrapper();
  const authToken = tokenClaims?.__raw;

  const getProductLists = useCallback(
    async (searchApi, authToken, isSSR) => {
      return await Promise.all(
        listsToReturn.map((list, index) => {
          const args = productListArgs[list];
          const shouldBeProcessed = !ssrDeplist || isSSR === undefined || isSSR === ssrDeplist[index];
          if (!shouldBeProcessed) return null;
          return getProducts(...args, categoryPath)(searchApi, authToken);
        })
      );
    },
    //todo: another dependency which should be immutable: listsToReturn
    [categoryPath]
  );

  const { data, setData } = useFrontload(
    'useSearchProducts',
    async ({ searchApi }) => await getProductLists(searchApi, authToken, true)
  );

  const productLists = Array.isArray(data)
    ? data.map((item) => {
        const body = getValidResponseBody(item);
        return body?.hits?.hits?.map(({ _source, _id }) => ({ ..._source, id: _id }));
      })
    : [];

  useClientEffect(() => {
    (async () => {
      const productLists = await getProductLists(searchApi, authToken);
      setData(() => productLists);
    })();
    //todo: check why setData in dependencies causes infinite loop
  }, [authToken, getProductLists]);

  useEffect(() => {
    (async () => {
      //first client side render
      const clientSideProductLists = await getProductLists(searchApi, authToken, false);
      const productLists = data.map((item, index) => {
        if (item !== null) return item;
        return clientSideProductLists[index];
      });
      setData(() => productLists);
    })();
  }, []);

  return productLists;
};
